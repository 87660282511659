<template>
  <div>
    <v-card
      flat
    >
      <v-card-text>
        <v-row>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.date"
                label="Date Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.time"
                label="Time Deposited"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.bank_code_name"
                label="Bank Code"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="deposit_slips.bank_depositories.account_no"
                label="Bank Account #"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="amount_formated"
                label="Amount"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="balance_formated"
                label="Balance"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center text-uppercase">
            Reference #
          </th>
          <th class="text-center text-uppercase">
            Code
          </th>
          <th class="text-center text-uppercase">
            Date & Time
          </th>
          <th class="text-center text-uppercase">
            Amount
          </th>
          <th class="text-center text-uppercase">
            Action
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in liquidations"
          :key="item.id"
        >
          <td class="text-center">
            {{ item.reference_no
            }}
          </td>
          <td class="text-center">
            {{ item.code }}
          </td>
          <td class="text-center">
            {{ date_format(item.date).format('MMMM DD, YYYY HH:mm:ss') }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.grand_total )}}
          </td>

          <td class="text-center">
            <v-icon
              v-show="item.deposit_slip_id===null"
              v-if="!is_deleting"
              class="mr-2"
              color="success"
              @click="load_to_deposit(item.id,get_total_amount(item.list_of_orders))"
            >
              {{icons.mdiCheck}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-else></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiCheck} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      bank_account_no: '',

      saving: false,
      alert: false,
      alert_message: '',
      is_deleting: false,
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiCheck,
        },
      }
    },
    data() {
      return initialState()
    },
    props: {
      balance_formated: String,
      amount_formated: String,
      deposit_slips: Object,
      liquidations: Array,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'user_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('bank_depository', ['find_bank_active']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      get_total_amount(details) {
        var total = 0
        details.forEach(function (item) {
          total += parseFloat(item.amount)
        })
        return total
      },
      load_to_deposit(id, amount) {
        this.$emit('data', {
          balance: parseFloat(this.deposit_slips.balance) - parseFloat(amount),
          amount: parseFloat(amount),
          id: id,
          deposit_slip_id: this.deposit_slips.id,
        })
      },
    }
  }
</script>
